import API, { authenticate } from '../utils/API';

const sendAvailabilityInquiry = (body) =>
  API.post(`/inquiries/private-activity-inquiry`, body, {
    ...authenticate(),
  });

export default {
  sendAvailabilityInquiry,
};
