import API, { authenticate } from '../utils/API';

const fetchBookings = () =>
  API.get('/activities/schedules/bookings', { ...authenticate() });

const fetchBookingById = (id) =>
  API.get(`/activities/schedules/bookings/${id}`, { ...authenticate() });

const bookActivity = ({ id, scheduleId, body }) =>
  API.post(`/activities/${id}/schedules/${scheduleId}/bookings`, body, {
    ...authenticate(),
  });

const cancelBookingById = (id) => 
  API.put(`/activities/schedules/bookings/${id}`, {
    bookingStatus: 2
  }, {
    ...authenticate(),
  });

const fetchPaymentDetailsByBookingId = (id) => 
  API.get(`/activities/bookings/payment-details/${id}`);

  export default {
  fetchBookings,
  fetchBookingById,
  bookActivity,
  fetchPaymentDetailsByBookingId,
  cancelBookingById,
};
