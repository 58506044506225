import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card, CardBody } from 'shards-react';
import classNames from 'classnames';
import {
  FaInstagram,
  FaYoutube,
  FaLinkedin,
  FaMedium,
  FaTwitter,
  FaFacebookSquare,
} from 'react-icons/fa';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import {
  FiArrowLeft,
  FiChevronsRight,
  FiCalendar,
  FiClock,
  FiMapPin,
  FiBriefcase,
  FiCheckCircle,
  FiMinusCircle,
} from 'react-icons/fi';
import { HiOutlineOfficeBuilding, HiOutlineChatAlt2, HiOutlineShieldCheck } from 'react-icons/hi';
import Datetime from 'react-datetime';
import moment from 'moment';
import history from '../../utils/history';
import { languages } from '../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchActivity } from './exploreEffects';
import { updateCheckout } from '../bookings/bookingsSlice';
import { Stepper } from '../../components/ui-kit';
import PageLoader from '../../components/PageLoader';
import ReviewItem from '../../components/ReviewItem';
import Flag from 'react-world-flags'
import queryString from 'query-string';

import 'react-bnb-gallery/dist/style.css';

import { useForm } from 'react-hook-form';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import InquiryModal from './InquiryModal';
import ActivityDetailsSection from './ActivityDetailsSection';
import EditorMultilangRenderer from '../../components/EditorMultilangRenderer';

const activityDetailsSections = [
  {
    key: 'description',
    title: 'Overview',
    collapsed: false,
  },
  {
    key: 'whatToBring',
    title: 'What to Bring',
    collapsed: true,
  },
  {
    key: 'cancellationPolicy',
    title: 'Cancellation Policy',
    collapsed: true,
  },
  {
    key: 'termsAndConditions',
    title: 'Terms & Conditions',
    collapsed: true,
  },

];

const ActivityPage = (props) => {
  const storeDispatch = useDispatch();
  useEffect(() => {
    storeDispatch(fetchActivity({
      id: props.match.params.slug || props.match.params.id,
      isSlug: !!props.match.params.slug,
    }));
  }, []);

  const userRole = useSelector((state) => state.user.details.role);
  const activity = useSelector((state) =>
    state.explore.entities[!!props.match.params.slug
      ? state.explore.ids.find(id =>
          state.explore.entities[id].slug === props.match.params.slug
        )
      : props.match.params.id
    ]
  );

  const photos =
    activity && activity.photoUrls
      ? activity.photoUrls.map((photoUrl, index) => ({
        src: photoUrl,
      }))
      : null;

  useEffect(() => {
    document.title = `Explore the world on Bondai ${activity ? `| ${activity.activityTitle}` : ''
      }`;
    const { date } = queryString.parse(props.location.search);
    if (date) {
      setSelectedDates([ moment.parseZone(date).format('MM/DD/YYYY') ]);
    }
  }, []);

  const isPreview =
    history.location.state && history.location.state.redirect === 'manage';

  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  const [schedulesDates, setSchedulesDates] = useState([]);
  const [schedules, setSchedules] = useState({});

  useEffect(() => {
    setSchedules(activity && activity.schedules
      ? activity.schedules.filter(schedule => !!schedule.spacesLeft).reduce(
        (accSchedules, schedule) => ({
          ...accSchedules,
          [schedule.id]: {
            ...schedule,
            startDateTime: moment.parseZone(schedule.startDateTime).local(true).format(),
            endDateTime: moment.parseZone(schedule.endDateTime).local(true).format(),
          },
        }),
        {},
      )
      : {});
  }, [selectedDates, activity]);

  const [inquiryDate, setInquiryDate] = useState([]);

  useEffect(() => {
    setSchedulesDates(Object.keys(schedules).map(scheduleId => moment(schedules[scheduleId].startDateTime).format('MM/DD/YYYY')));
  }, [schedules]);

  useEffect(() => {
    if (activity && activity.isPrivate && activity.schedules?.length) {
      setSelectedSchedule(activity.schedules[0].id);
      setSelectedDates([moment.parseZone(activity.schedules[0].startDateTime).format('MM/DD/YYYY')]);
    }
  }, [activity]);

  const onCheckout = (data) => {
    storeDispatch(
      updateCheckout({
        adultTickets: +data.AdultTickets || 0,
        childTickets: +data.ChildTickets || 0,
        extras: schedules[selectedSchedule]?.availablePaidExtras.reduce(
          (extras, extraId, index) => [
            ...extras,
            ...(parseInt(data[`Extras${index}`])
              ? [{ id: extraId, count: parseInt(data[`Extras${index}`]) }]
              : []),
          ],
          [],
        ),
        activity: activity.shortId,
        schedule: selectedSchedule,
      }),
    );
    history.push('/checkout');
  };

  const { register, handleSubmit, watch, reset, setValue } = useForm({
    defaultValues: {
      AdultTickets: 1,
      ChildTickets: 0,
    },
  });
  const total =
    schedules && schedules[selectedSchedule] && activity
      ? +watch('AdultTickets') *
      +schedules[selectedSchedule].ticketTypes[0].priceAmount +
      (schedules[selectedSchedule].ticketTypes[1]?.priceAmount
        ? +watch('ChildTickets') *
        +schedules[selectedSchedule].ticketTypes[1].priceAmount
        : 0) +
      schedules[selectedSchedule].availablePaidExtras.reduce(
        (extrasSum, extraId, index) =>
          extrasSum +
          (extraId
            ? +activity.paidExtras[extraId].priceAmount *
            (+watch(`Extras${index}`) || 0)
            : 0),
        0,
      )
      : 0;
  const [isInquiryVisible, setIsInquiryVisible] = useState(false);
  const [isBookingPublicTrip, setIsBookingPublicTrip] = useState(true);

  return !photos ? (
    <PageLoader label='Fetching experience details...' />
  ) : (
      <div className='activity'>
        <div className='activity__wrapper'>
          <div className='activity__container'>
            <div className='activity__details'>
              <div
                style={{
                  display: 'block',
                  fontWeight: 700,
                  cursor: 'pointer',
                  width: '100%',
                }}
                className='backLink'
                onClick={() => {
                  props.history.push(
                    isPreview ? `/dashboard/manage/${activity.id}` : '/explore',
                  );
                }}
              >
                <FiArrowLeft style={{ height: 24, width: 24, marginRight: 5 }} />
                {isPreview ? 'manage experience' : 'explore other experiences'}
              </div>
              <div className='activity__header'>
                <div className='activity__featured'>
                  <div className='activity__title'>{activity.activityTitle}</div>
                  <div className='activity__featuredList'>
                    {process.env.REACT_APP_HIDE_PROVIDER_NAME === 'true' ? null : (
                      <div className='activity__featuredItem'>
                        <FiBriefcase className='activity__featuredIcon' />
                        <div>
                          <span>Organized by</span>
                          {activity.tourProvider.tourProviderName}
                        </div>
                      </div>
                    )}
                    <div className='activity__featuredItem' style={{ ...styles.featuredItem }}>
                      <FiMapPin className='activity__featuredIcon' />
                      <div>
                        <span>Location</span>

                        {activity.destination
                          ? `${activity.destination.localityName
                            ? `${activity.destination.localityName}, `
                            : ''
                          } ${activity.destination.regionName || 'Saudi Arabia'
                          }`
                          : 'Saudi Arabia'}
                      </div>
                    </div>
                    <div className='activity__featuredItem' style={{ ...styles.featuredItem }}>
                      <FiClock className='activity__featuredIcon' />
                      <div>
                        <span>Duration</span>
                        {activity.defaultDurationInDays !== 1
                          ? `${activity.defaultDurationInDays} days`
                          : 'day trip'}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='activity__photos'>
                  <ImageGallery
                    items={activity.photoUrls.map((photoUrl) => ({
                      original: photoUrl + '720',
                      thumbnail: photoUrl + '320',
                      originalClass: 'activity__photosOriginal',
                      thumbnailClass: 'activity__photosThumbnail',
                    }))}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    useBrowserFullscreen={false}
                    additionalClass='activity__photosGallery'
                    thumbnailPosition={
                      window.innerWidth < 768 ? 'bottom' : 'left'
                    }
                  // showBullets={true}
                  />
                </div>
              </div>
              <div className='activity__description'>
                <div className='activity__descriptionRow'>
                  {activity.including ? (
                    <Card className='activity__descriptionCard'>
                      <CardBody style={{ padding: '10px 20px' }}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            display: 'block',
                            marginBottom: 10,
                          }}
                        >
                          <FiCheckCircle
                            style={{ width: 18, height: 18, marginRight: 5 }}
                          />
                        INCLUDING
                      </span>
                        {activity.including}
                      </CardBody>
                    </Card>
                  ) : null}
                  {activity.excluding ? (
                    <Card className='activity__descriptionCard'>
                      <CardBody style={{ padding: '10px 20px' }}>
                        <span
                          style={{
                            fontSize: 14,
                            fontWeight: 600,
                            display: 'block',
                            marginBottom: 10,
                          }}
                        >
                          <FiMinusCircle
                            style={{ width: 18, height: 18, marginRight: 5 }}
                          />
                        EXCLUDING
                      </span>
                        {activity.excluding}
                      </CardBody>
                    </Card>
                  ) : null}
                </div>
                <Row>
                  <Col xs={12}>
                    {activityDetailsSections.map(({ key, title, collapsed }) =>
                      activity[key] ? (
                        <ActivityDetailsSection {...{ title, collapsed }}>
                          <EditorMultilangRenderer content={activity[key]} />
                        </ActivityDetailsSection>
                      ) : null,
                    )}
                    <ActivityDetailsSection title={`${activity.ratingCount} review${activity.ratingCount > 1 ? 's' : ''} (${parseFloat(activity.globalRating.toFixed(2))})`} collapsed={true}>
                      {activity.reviews?.length ? (
                      <Row>
                        {activity.reviews.map((review) => (
                          <ReviewItem 
                            rating={review.rating}
                            name={`${review.reviewerDetails.firstName} ${review.reviewerDetails.lastName[0].toUpperCase()}.`}
                            avatarUrl={review.reviewerDetails.profilePictureUrl}
                            submissionDate={moment.utc(review.createdDateTime).local().format('MMM Do, YYYY')}
                            experienceDate={moment
                              .utc(review.activityDetails.scheduleStartDateTime)
                              .local().format('MMM Do, YYYY')}
                            feedback={review.publicFeedback}
                          />
                        ))}
                      </Row>
                      ) : null}
                    </ActivityDetailsSection>
                  </Col>
                </Row>
              </div>
              {activity.tourProvider.profilePictureUrl && activity.tourProvider.description ? (
                <div className='activity__provider'>
                  <div className='activity__providerLogo'>
                    <img src={activity.tourProvider.profilePictureUrl} alt={activity.tourProvider.tourProviderName} />
                  </div>
                  <div className='activity__providerDetails'>
                    <div className='activity__providerTitle'>
                      {`About ${process.env.REACT_APP_HIDE_PROVIDER_NAME === 'true' ? 'the organizer' : activity.tourProvider.tourProviderName}`}
                    </div>
                    <div className='activity__featuredList' style={{ marginTop: 20 }}>
                      {
                        activity.tourProvider.operatingLicence ?
                          <div className='activity__featuredItem'>
                            <HiOutlineShieldCheck className='activity__featuredIcon' />
                            <div>
                              <span>Operating Licence</span>
                              {activity.tourProvider.operatingLicence}
                            </div>
                          </div> : null
                      }
                      {
                        activity.tourProvider.tourProviderLocationDto ?
                          <div className='activity__featuredItem'>
                            <HiOutlineOfficeBuilding className='activity__featuredIcon' />
                            <div>
                              <span>Location</span>
                              {`${activity.tourProvider.tourProviderLocationDto.localityName}, ${activity.tourProvider.tourProviderLocationDto.countryDestinationName}`}
                            </div>
                          </div> : null
                      }
                      {
                        activity.tourProvider.spokenLanguages?.length ?
                          <div className='activity__featuredItem'>
                            <HiOutlineChatAlt2 className='activity__featuredIcon' />
                            <div>
                              <span>Speaking</span>
                              {activity.tourProvider.spokenLanguages.map(lang => (
                                <Flag code={languages[lang].flagCountryCode} height={16} className='activity__providerLanguageIcon' />
                              ))}
                            </div>
                          </div> : null
                      }
                    </div>
                    <div className='activity__providerDescription'>
                      {activity.tourProvider.description}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className='activity__sidebar'>
              <div className='activity__sidebarContainer'>
                {(activity.defaultPrices[0] || activity.startingFromPrices[0]) && !activity.isPrivate ? (
                  <div className='activity__pricing'>
                    Starting from
                    <span>
                      {` ${activity.defaultPrices[0]?.priceAmount || activity.startingFromPrices[0].priceAmount} ${activity.currency.id} `}
                    </span>
                  / adult
                  </div>
                ) : null}
                {!activity.isPrivate ? <div className='schedule__types'>
                  <div className={classNames({
                    'schedule__typesItem': true,
                    'schedule__typesItem--isActive': isBookingPublicTrip,
                  })} onClick={() => { setIsBookingPublicTrip(true); }}><span>Book</span> this public trip</div>
                  <div className={classNames({
                    'schedule__typesItem': true,
                    'schedule__typesItem--isActive': !isBookingPublicTrip,
                  })} onClick={() => { setIsBookingPublicTrip(false); }}><span>Request</span> private trip</div>
                </div> : null}
                <Card className='schedule'>
                  <CardBody style={{ padding: 0 }}>
                    {selectedSchedule && !activity.isPrivate && isBookingPublicTrip ? (
                      <span
                        className='schedule__backBtn'
                        onClick={() => {
                          setSelectedSchedule(null);
                          reset();
                        }}
                      >
                        <FiArrowLeft className='schedule__titleIcon' />
                      see more dates
                      </span>
                    ) : (activity.isPrivate ? null : (
                      <span className='schedule__title'>
                        <FiCalendar className='schedule__titleIcon' />
                        {'Select preferred dates'}
                      </span>
                    ))}
                    <div className='schedule__list'>
                      {activity.isPrivate || (isBookingPublicTrip && selectedSchedule) ? null :
                        <Datetime
                          renderInput={() => null}
                          onChange={(date) => {
                            const selectedDate = date.format('MM/DD/YYYY');
                            if (selectedDates?.includes(selectedDate)) {
                              setSelectedDates(
                                selectedDates.filter(
                                  (dateSelected) => dateSelected !== selectedDate,
                                ),
                              );
                            } else {
                              setSelectedDates([...selectedDates, selectedDate]);
                            }
                          }}
                          dateFormat={'MM/DD/YYYY'}
                          timeFormat={false}
                          renderDay={(propsRender, currentDate, value) => {
                            const formattedCurrentDate = currentDate.format('MM/DD/YYYY');
                            if (currentDate.isBefore(moment().startOf('day'))) {
                              return (
                                <td
                                  style={{
                                    color: '#dedede',
                                    backgroundColor: 'white',
                                  }}
                                >
                                  {currentDate.date()}
                                </td>
                              );
                            }
                            return (
                              <td
                                {...propsRender}
                                style={{
                                  color: selectedDates?.includes(formattedCurrentDate)
                                    ? 'white'
                                    : 'black',
                                  borderRadius: 8,
                                  border: '2px solid white',
                                  backgroundColor: selectedDates?.includes(formattedCurrentDate)
                                    ? '#f7414e'
                                    : (isBookingPublicTrip && schedulesDates?.includes(formattedCurrentDate) ? '#d3eed3' : 'white'),
                                }}
                              >
                                {currentDate.date()}
                              </td>
                            );
                          }}
                          open={true}
                          closeOnSelect={false}
                          defaultValue={selectedDates[0] || new Date()}
                        />
                      }
                      {isBookingPublicTrip && 
                      selectedDates?.sort().map((selection) => 
                        schedulesDates.includes(selection) ? 
                        Object.keys(schedules).filter((scheduleId) => moment(schedules[scheduleId].startDateTime).format('MM/DD/YYYY') === selection)
                        .map(scheduleId => (
                          <div
                            className={`schedule__entry ${selectedSchedule && selectedSchedule !== scheduleId
                              ? 'schedule__entry--hidden'
                              : ''
                              }  ${selectedSchedule ? 'schedule__entry--isHovered' : ''
                              }`}
                          >
                            <div className='schedule__entryRow'>
                              <span className='schedule__entryDate'>
                                {moment.parseZone(schedules[scheduleId].startDateTime).local(true).format(
                                  'ddd, MMM Do',
                                )}
                              </span>
                              <span className='schedule__entryAvailability'>
                                {`${schedules[scheduleId].spacesLeft === 0
                                  ? 'sold out'
                                  : `${schedules[scheduleId].spacesLeft} places left`
                                  }`}
                              </span>
                            </div>
                            <div className='schedule__entryRow'>
                              {!selectedSchedule ? (
                                <div className='schedule__entryPrices'>
                                  {schedules[scheduleId].ticketTypes[0] &&
                                    schedules[scheduleId].ticketTypes[0].priceAmount ? (
                                      <span className='schedule__entryPrice'>
                                        {`${schedules[scheduleId].ticketTypes[0].priceAmount} ${activity.currency.id} `}
                                        <span>/ adult</span>
                                      </span>
                                    ) : null}
                                  {schedules[scheduleId].ticketTypes[1] &&
                                    schedules[scheduleId].ticketTypes[1].priceAmount ? (
                                      <span className='schedule__entryPrice'>
                                        {`${schedules[scheduleId].ticketTypes[1].priceAmount} ${activity.currency.id} `}
                                        <span>/ child</span>
                                      </span>
                                    ) : null}
                                </div>
                              ) : null}

                              <div className='schedule__entrySelectBtn'>
                                {selectedSchedule ? (
                                  <span className='schedule__entrySelectLabel'>
                                    Select your tickets
                                  </span>
                                ) : (
                                    <Button
                                      theme='dark'
                                      size='small'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedSchedule(scheduleId);
                                        reset();
                                      }}
                                      disabled={!schedules[scheduleId].spacesLeft}
                                    >
                                      SELECT
                                    </Button>
                                  )}
                              </div>
                            </div>
                          </div>
                        )) : (
                          <div className={`schedule__entry ${selectedSchedule ? 'schedule__entry--hidden' : ''}`}>
                            <div className='schedule__entryRow'>
                              <span className='schedule__entryDate'>
                                {moment(selection).format('MMM Do')}
                              </span>
                              <div className='schedule__entryInquiryBtn'>
                                <Button
                                  className='customButton--red'
                                  theme='dark'
                                  size='small'
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setInquiryDate([moment(selection).format('DD/MM/YYYY')]);
                                    setIsInquiryVisible(true);
                                  }}
                                >
                                  INQUIRE FOR AVAILABILITY
                                </Button>
                              </div>
                            </div>
                          </div>)
                      )}

                      <form
                        onSubmit={handleSubmit(onCheckout)}
                        className='schedule__checkout'
                      >
                        {selectedSchedule && schedules[selectedSchedule] && isBookingPublicTrip ? (
                          <>
                            <div className='schedule__tickets'>
                              <div className='schedule__ticketType'>
                                <div>
                                  <span>Adult tickets</span>
                                  <span>
                                    {`${schedules[selectedSchedule].ticketTypes[0].priceAmount} ${activity.currency.id} `}
                                  </span>
                                </div>
                                <Stepper
                                  name='AdultTickets'
                                  max={
                                    schedules[selectedSchedule].spacesLeft -
                                    parseInt(watch('ChildTickets'))
                                  }
                                  min={1}
                                  controls={{ register, setValue, watch }}
                                />
                              </div>
                              {schedules[selectedSchedule].ticketTypes[1]
                                ?.priceAmount ? (
                                  <div className='schedule__ticketType'>
                                    <div>
                                      <span>Child tickets</span>
                                      <span>
                                        {`${schedules[selectedSchedule].ticketTypes[1].priceAmount} ${activity.currency.id} `}
                                      </span>
                                    </div>
                                    <Stepper
                                      name='ChildTickets'
                                      max={
                                        schedules[selectedSchedule].spacesLeft -
                                        parseInt(watch('AdultTickets'))
                                      }
                                      min={0}
                                      controls={{ register, setValue, watch }}
                                    />
                                  </div>
                                ) : null}
                            </div>

                            {schedules[selectedSchedule].availablePaidExtras
                              .length && activity ? (
                                <div className='schedule__paidExtras'>
                                  <span className='schedule__title'>
                                    {/* <MdAddToPhotos className='schedule__titleIcon' /> */}
                                Available paid extras
                              </span>
                                  {schedules[
                                    selectedSchedule
                                  ].availablePaidExtras.map((id, index) => (
                                    <div className='schedule__ticketType'>
                                      <div>
                                        <span>{activity.paidExtras[id].label}</span>
                                        <span>
                                          {`${activity.paidExtras[id].priceAmount} ${activity.currency.id}`}
                                        </span>
                                      </div>
                                      <Stepper
                                        name={`Extras${index}`}
                                        min={0}
                                        controls={{ register, setValue, watch }}
                                      />
                                    </div>
                                  ))}
                                </div>
                              ) : null}

                            {+watch('AdultTickets') || +watch('ChildTickets') ? (
                              <>
                                <div className='schedule__checkoutTotal'>
                                  {`Total: ${total} ${activity.currency.id}`}
                                </div>
                                <div className='schedule__checkoutProceed'>
                                  {userRole === 'user' || !userRole ? (
                                    <Button theme='dark' outline>
                                      PROCEED TO CHECKOUT
                                      <FiChevronsRight />
                                    </Button>
                                  ) : (
                                      "At the moment, your account type isn't eligible to book experiences."
                                    )}
                                </div>
                              </>
                            ) : null}
                          </>
                        ) : null}
                      </form>
                    </div>
                    {
                      activity.parentActivityId ||
                        activity.isPrivate ||
                        isBookingPublicTrip ? null : (
                          <div className='activity__inquiryTrigger'>
                            Inquire below and we'll get back to you as soon as possible.
                            <Button
                              className='customButton--red'
                              onClick={(e) => {
                                e.preventDefault();
                                setIsInquiryVisible(true);
                              }}
                              disabled={userRole && userRole !== 'user'}
                            >
                              INQUIRE FOR AVAILABILITY
                      </Button>
                          </div>
                        )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <InquiryModal
          isOpen={isInquiryVisible}
          toggleVisibility={() => setIsInquiryVisible(!isInquiryVisible)}
          setVisibility={(isOpen) => setIsInquiryVisible(isOpen)}
          tourOperator={activity.tourProvider.tourProviderName}
          activityId={activity.id}
          activityTitle={activity.activityTitle}
          selectedDates={isBookingPublicTrip ? inquiryDate : selectedDates.map(date => moment(date).format('DD/MM/YYYY'))}
          onSelectionChanged={setSelectedDates}
        />
      </div >
    );
};
const styles = {
  featuredItem: { ...process.env.REACT_APP_HIDE_PROVIDER_NAME === 'true' ? { flex: 1 }: {} },
};

export default ActivityPage;
