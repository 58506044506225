import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FiX } from 'react-icons/fi';

const Filter = ({ isSticky, isHidden, isTriggered, onTrigger, onDismiss, selection, content, placeholderIcon, placeholderText, onClear }) => {
  const contentRef = useRef(null);
  useEffect(() => {
    if (contentRef && isTriggered) {
      contentRef.current.firstChild.click();
    }
  }, [contentRef, isTriggered]);

  return (isHidden ? null : (
    <div className="container" onClick={() => { !isTriggered && onTrigger(); }}>
      <div className="header">
        {placeholderIcon ? (
          <div className="icon">{placeholderIcon}</div>
        ) : null}
        <div className="text">
          {selection && !isTriggered ? selection : (isTriggered ? (
            <div className="content" ref={contentRef}>
              {content}
            </div>
          ) : placeholderText)}
        </div>
        {isTriggered ? (
          <div className="icon closeBtn" onClick={() => { onDismiss(); }}>
            <FiX size={20}/>
          </div>
        ) : null}
      </div>
      {/* {isTriggered ? (
        <div className="content">
          {content}
        </div>
      ) : null} */}
      {isTriggered && selection && onClear ? (
        <div
          className="clear"
          onClick={() => {
            onClear();
            onDismiss();
          }}
        >
          CLEAR FILTER
        </div>
      ) : null}
      {/* order: ${selection ? 0 : (isTriggered && isSticky ? -1 : 1)}; */}
      <style jsx>
        {`
        .container {
          position: relative;
          display: flex;
          flex-direction: column;
          width: ${selection || isTriggered ? '100%' : 'calc(50% - 10px)'};
          background: ${selection && !isTriggered ? 'black' : 'white'};
          color: ${selection && !isTriggered ? 'white' : 'black'};
          font-size: 14px;
          font-weight: 600;
          padding: ${isSticky && isTriggered ? '8px 0px 0px' : '8px 10px'};
          justify-content: flex-start;
          align-items: flex-start;
          border-radius: 8px;
          border: ${isSticky && isTriggered ? 'none' : '2px solid black'};
          margin: ${isSticky && isTriggered ? '0px' : '5px'};
          min-width: ${isTriggered && isSticky ? '100%' : 'auto'};
          overflow-x: hidden;
        }
        .header {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: ${selection && !isTriggered ? 'center' : 'flex-start'};
          align-items: flex-start;
        }
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          z-index: 9999999999999;
        }
        .closeBtn {
          margin-right: 0;
        }
        .text {
          ${isTriggered ? 'flex: 1;' : ''}
          white-space: ${isSticky ? 'nowrap' : 'normal'};
          text-align: left;
        }
        .content {
          background: white;
          color: black;
          position: relative;
          height: auto;
          width: 240px;
          border-radius: 8px;
          padding: 0;
        }
        .clear {
          font-size: 12px;
          font-weight: 700;
          width: 100%;
          padding: 10px 10px 4px;
          text-align: center;
        }
        @media (min-width: 768px) {
          .container {
            width: auto;
          }
        }
      `}
      </style>
    </div>
  ));
}

Filter.propTypes = {
  selection: PropTypes.string,
  placeholderIcon: PropTypes.func,
  placeholderText: PropTypes.string.isRequired,
};

Filter.defaultProps = {
  selection: '',
  placeholderIcon: null,
};

export default Filter;
