import { createAsyncThunk } from '@reduxjs/toolkit';
import AccountAPI from '../../api/AccountAPI';
import TourProviderAPI from '../../api/TourProviderAPI';
import CurrencyAPI from '../../api/CurrencyAPI';
import DestinationAPI from '../../api/DestinationAPI';
import ActivityTagsAPI from '../../api/ActivityTagsAPI';
import InquiriesApi from '../../api/InquiriesApi';
import FormsAPI from '../../api/FormsAPI';
import { notify } from '../notifications/notificationsEffects';
import { showModal } from '../notifications/notificationsSlice';
import { logout } from './userSlice';
import history from '../../utils/history';

export const login = createAsyncThunk(
  'user/loginStatus',
  async (authDetails, { dispatch }) => {
    try {
      const { data } = await AccountAPI.login(authDetails);

      localStorage.setItem('token', data.value.token);
      localStorage.setItem('role', data.value.details.role);

      return data.value.details;
    } catch (err) {
      if (err.response.status === 401) {
        dispatch(
          notify({
            message: `Sorry, email and password does not match.`,
            type: 'danger',
          }),
        );
      } else {
        dispatch(
          notify({
            message: `Oups, something went wrong! Please try again later.`,
            type: 'warning',
          }),
        );
      }
      throw err;
    }
  },
);

export const loginAs = createAsyncThunk(
  'user/loginAsStatus',
  async ({ email, token = null}, { dispatch }) => {
    try {
      const { data } = await AccountAPI.loginAs(email, token);
      // localStorage.setItem('superadmin', token ? '' : localStorage.getItem('token'));
      localStorage.setItem('token', data.value);
      localStorage.setItem('role', 'tourProviderAdmin');
      const response = await AccountAPI.fetchDetails();
      return response.data.value;
    } catch (err) {
      dispatch(
        notify({
          message: `Oups, something went wrong! Please try again later.`,
          type: 'warning',
        }),
      );
      throw err;
    }
  },
);

export const register = createAsyncThunk(
  'user/registerStatus',
  async (body, { dispatch }) => {
    try {
      const { data } = await AccountAPI.register(body);
      localStorage.setItem('token', data.value.token);
      localStorage.setItem('role', data.value.details.role);
      return data.value.details;
    } catch (err) {
      dispatch(
        notify({
          message: `Oups, something went wrong! ${err.response.data.notificationMessage}`,
          type: 'warning',
        }),
      );
      throw err;
    }
  },
);
export const fetchDetails = createAsyncThunk('user/fetchDetails', async (token) => {
  const response = await AccountAPI.fetchDetails(token);
  return response.data.value;
});

export const updateDetails = createAsyncThunk(
  'user/updateDetails',
  async (body, { dispatch }) => {
    const response = await AccountAPI.updateDetails(body);
    return response.data.value;
  },
);

export const fetchActivities = createAsyncThunk(
  'user/fetchActivitiesStatus',
  async () => {
    //handle errors
    const { data } = await AccountAPI.fetchActivities();
    return data.value;
  },
);

export const fetchActivityStatus = createAsyncThunk(
  'user/fetchBucketListStatus',
  async ({ id, shortId }) => {
    const { data } = await AccountAPI.fetchActivityStatus(id);
    if (data.value !== 'Confirmed')
      throw new Error({
        name: 'BucketListStatus',
        message: 'Activity not added to bucket list',
      });
    return { shortId };
  },
);

export const addToBucketList = createAsyncThunk(
  'user/addToBucketListStatus',
  async ({ id, shortId }, { dispatch }) => {
    await AccountAPI.addActivityToBucketList(id);
    dispatch(
      notify({
        message: `You've successfully added the activity on your bucket list.`,
        type: 'success',
      }),
    );
    //TODO notify and redirect to explore
    return { shortId };
  },
);

export const removeFromBucketList = createAsyncThunk(
  'user/removeFromBucketListStatus',
  async ({ id, shortId }, { dispatch }) => {
    await AccountAPI.removeActivityFromBucketList(id);
    dispatch(
      notify({
        message: `You've successfully removed the activity from your bucket list.`,
        type: 'success',
      }),
    );
    return shortId;
  },
);

export const handleBucketListActivity = createAsyncThunk(
  'user/handleBucketStatus',
  async (body, { dispatch }) => {
    let response;
    if (body.firstName) {
      response = await AccountAPI.registerBucketListActivity(body);
      dispatch(
        notify({
          message: `You've successfully created your Bondai account and added the first activity to your bucket list. Well done!`,
          type: 'success',
        }),
      );
    } else {
      response = await AccountAPI.loginBucketListActivity(body);
      //TODO display activity name in notification
      dispatch(
        notify({
          message: `Welcome back, ${response.data.value.details.firstName}! You've successfully added the activity to your bucket list.`,
          type: 'success',
        }),
      );
    }
    //TODO handle errors
    localStorage.setItem('token', response.data.value.token);
    localStorage.setItem('role', 'user');
    dispatch(fetchActivities());
    return {
      details: response.data.value.details,
      activity: { shortId: body.activityShortId },
    };
  },
);

export const registerProvider = createAsyncThunk(
  'user/onboardingStatus',
  async (body) => {
    try {
      const { data } = await TourProviderAPI.register(body);
      localStorage.setItem('token', data.value.token);
      localStorage.setItem('role', 'tourProviderAdmin');
      return data.value.details;
    } catch (err) { }
  },
);

export const updateProviderDetails = createAsyncThunk(
  'user/updateProviderDetailsStatus',
  async (data, { dispatch }) => {
    try {
      const body = new FormData();
      body.append('TourProviderName', data.TourProviderName);
      body.append('OperatingLicence', data.OperatingLicence);
      body.append('Description', data.Description);
      if (data.ProfilePicture.raw) {
        body.append('ProfilePicture', data.ProfilePicture.raw);
      }
      if (data.LocationGooglePlaceId.value) {
        body.append('LocationGooglePlaceId', data.LocationGooglePlaceId.value);
      }
      data.SpokenLanguages.forEach(lang => {
        body.append('SpokenLanguages', lang);
      });
      const response = await TourProviderAPI.updateDetails(body);
      dispatch(
        notify({
          message: `Your profile has been updated successfully.`,
          type: 'success',
        }),
      );
      return response.data.value;
    } catch (err) {
      dispatch(
        notify({
          message: `Oups, something went wrong and your profile could not be updated! Please try again later.`,
          type: 'danger',
        }),
      );
    }
  },
);


export const checkIfAccountExists = createAsyncThunk(
  'user/checkExistingStatus',
  async (email) => {
    const { data } = await AccountAPI.checkIfAccountExists(email);
    return data.success && data.value;
  },
);

export const fetchCurrencies = createAsyncThunk(
  'user/fetchCurrenciesStatus',
  async () => {
    const { data } = await CurrencyAPI.fetchCurrencies();
    return data.value;
  },
);

export const fetchDestinations = createAsyncThunk(
  'user/fetchDestinationsStatus',
  async () => {
    const { data } = await DestinationAPI.fetchDestinations();
    return data.value;
  },
);

export const fetchActivityTags = createAsyncThunk(
  'user/fetchActivityTagsStatus',
  async () => {
    const { data } = await ActivityTagsAPI.fetchActivityTags();
    return data.value;
  },
);

export const requestPasswordReset = createAsyncThunk(
  'user/requestPasswordResetStatus',
  async (email, { dispatch }) => {
    try {
      const { data } = await AccountAPI.requestPasswordReset({ email });
      history.push('/explore');
      dispatch(
        showModal({
          message: `Your password reset request was submitted. Please check your inbox for a password reset email shortly.`,
          dismissLabel: 'Keep exploring',
        }),
      );
    } catch (err) {
      dispatch(
        notify({
          message: `Oups! Either something went wrong and we couldn't send your reset password email or there is no account registered using this email.`,
          type: 'warning',
        }),
      );
    }
    return;
  },
);

export const resetPassword = createAsyncThunk(
  'user/resetPasswordStatus',
  async (body, { dispatch }) => {
    try {
      const { data } = await AccountAPI.resetPassword(body);
      dispatch(
        notify({
          message: `Your password has been successfully changed. Please login below.`,
          type: 'success',
        }),
      );
      history.push('/login');
    } catch (err) {
      dispatch(
        notify({
          message: `Oups! Something went wrong and we couldn't update your password at this time. Please try again later or submit another forgot password request.`,
          type: 'warning',
        }),
      );
    }
  },
);

export const handleAvailabilityInquiry = createAsyncThunk(
  'user/handleAvailabilityInquiryStatus',
  async (body, { dispatch }) => {
    try {
      await InquiriesApi.sendAvailabilityInquiry(body);
      dispatch(
        showModal({
          message: `Your availability inquiry was submitted. The tour operator will get back to you in the shortest time possible.`,
          dismissLabel: 'Keep exploring',
        }),
      );
      return true;
    } catch (err) {
      dispatch(
        notify({
          message: `Oups! Something went wrong and we couldn't submit your inquiry at this time. Please try again later or get in touch by email at hi@bondai.io.`,
          type: 'warning',
          time: 8000,
        }),
      );
    }
  },
);

export const handleActivityInquiry = createAsyncThunk(
  'user/handleActivityInquiryStatus',
  async (body, { dispatch }) => {
    try {
      await FormsAPI.sendActivityInquiry(body);
      dispatch(
        showModal({
          message: `Your experience inquiry was submitted. We'll get back to you in the shortest time possible.`,
          dismissLabel: 'Keep exploring',
        }),
      );
      return true;
    } catch (err) {
      dispatch(
        notify({
          message: `Oups! Something went wrong and we couldn't submit your inquiry at this time. Please try again later or get in touch by email at hi@bondai.io.`,
          type: 'warning',
          time: 8000,
        }),
      );
    }
  },
);
