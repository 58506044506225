import { createAsyncThunk } from '@reduxjs/toolkit';
import TourProviderAPI from '../../api/TourProviderAPI';
import ScheduleAPI from '../../api/ScheduleAPI';
import { notify } from '../notifications/notificationsEffects';
import { showModal } from '../notifications/notificationsSlice';
import history from '../../utils/history';
import ActivitiesAPI from '../../api/ActivitiesAPI';

//todo create API wrapper to handle 401 and redirect to login, alert messages and response format - response.data.value

export const fetchActivities = createAsyncThunk(
  'activities/fetchAllStatus',
  async () => {
    const response = await TourProviderAPI.fetchActivities();
    return response.data.value;
  },
);

//404 redirect to dashboard
export const fetchActivityById = createAsyncThunk(
  'activities/fetchByIdStatus',
  async (id, { dispatch }) => {
    try {
      const response = await TourProviderAPI.fetchActivityById(id);
      return response.data.value;
    } catch (err) {
      dispatch(
        notify({
          message:
            "The requested activity is either not available any longer or you're not authorized to manage it.",
          type: 'danger',
        }),
      );
      history.push('/dashboard');
      throw err;
    }
  },
);

export const removeActivity = createAsyncThunk(
  'activities/removeStatus',
  async (id, { dispatch }) => {
    try {
      const response = await TourProviderAPI.removeActivity(id);
      if (response.status === 200) {
        dispatch(
          notify({
            message: 'The activity has been deleted successfully!',
            type: 'success',
          }),
        );
        history.push('/dashboard');
      }
      return response.data.value;
    } catch (err) {
      dispatch(
        notify({
          message: 'The activity has not been deleted.',
          type: 'danger',
        }),
      );
      history.push('/dashboard');
      throw err;
    }
  },
);

export const setActivityVisibilityStatus = createAsyncThunk(
  'activities/visibilityStatus',
  async ({ id, value }, { dispatch }) => {
    try {
      const body = new FormData();
      body.append('IsPrivate', value);
      const response = await TourProviderAPI.updateActivity(id, body);
      if (!value) {
        dispatch(
          showModal({
            message: `Your experience listing will be reviewed shortly before being published. You will be notified by email when your experience will be publicly visible on bondai.io/explore.`,
            dismissLabel: 'Continue',
          }),
        );
      }
      dispatch(
        notify({
          message: 'The activity has been updated successfully!',
          type: 'success',
        }),
      );

      return response.data.value;
    } catch (err) {
      dispatch(
        notify({
          message: 'Something went wrong, the activity could not be updated.',
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const addActivity = createAsyncThunk(
  'activities/addStatus',
  async (data, { dispatch }) => {
    try {
      const body = new FormData();

      [
        ...data.photos.filter((photo) => photo.path === data.cover),
        ...data.photos.filter((photo) => photo.path !== data.cover),
      ].forEach((photo) => {
        body.append('NewPhotos', photo.file);
      });
      body.append('IsPrivate', data.IsPrivate);
      body.append('HasFlexibleSchedule', false);
      body.append('AdditionalInfo', '');
      body.append('GooglePlaceId', data.GooglePlaceId);
      data.TagIds.forEach((tagId) => {
        body.append('TagIds', tagId);
      });
      body.append('CurrencyId', data.CurrencyId);
      body.append('ActivityTitle', data.ActivityTitle);
      body.append('Description', data.Description);
      body.append('TermsAndConditions', data.TermsAndConditions);
      body.append('WhatToBring', data.WhatToBring);
      body.append('CancellationPolicy', data.CancellationPolicy);
      body.append('Including', data.Including);
      body.append('Excluding', data.Excluding);
      body.append(
        'DefaultDurationInDays',
        parseInt(data.DefaultDurationInDays),
      );
      body.append('PaidExtras', JSON.stringify(data.extras));
      body.append('DefaultPrices', JSON.stringify(data.DefaultPrices));

      const response = await TourProviderAPI.addActivity(body);
      if (!data.IsPrivate) {
        dispatch(
          showModal({
            message: `Your experience listing will be reviewed shortly before being published. You will be notified by email when your experience will be publicly visible on bondai.io/explore.`,
            dismissLabel: 'Continue',
          }),
        );
      }
      dispatch(
        notify({
          message: 'The activity has been added successfully!',
          type: 'success',
        }),
      );
      localStorage.removeItem('draft');
      history.push(`/dashboard/schedule/${response.data.value.id}`);
      return response.data.value;
    } catch (err) {
      dispatch(
        notify({
          message: `Something went wrong, the activity could not be added. ${err.response.data.notificationMessage || ''}`,
          type: 'danger',
        }),
      );
      // history.push('/dashboard');
      throw err;
    }
  },
);

export const updateActivity = createAsyncThunk(
  'activities/updateStatus',
  async (data, { dispatch }) => {
    const body = new FormData();
    data.TagIds.forEach((tagId) => {
      body.append('TagIds', tagId);
    });
    body.append('GooglePlaceId', data.GooglePlaceId);
    body.append('CurrencyId', data.CurrencyId);
    body.append('ActivityTitle', data.ActivityTitle);
    body.append('Slug', data.Slug);
    body.append('Description', data.Description);
    body.append('TermsAndConditions', data.TermsAndConditions);
    body.append('WhatToBring', data.WhatToBring);
    body.append('CancellationPolicy', data.CancellationPolicy);
    body.append('Including', data.Including);
    body.append('Excluding', data.Excluding);
    body.append('DefaultPrices', JSON.stringify(data.DefaultPrices));
    body.append('DefaultDurationInDays', parseInt(data.DefaultDurationInDays));
    [
      ...data.photos.filter((photo) => photo.path === data.cover),
      ...data.photos.filter((photo) => photo.path !== data.cover),
    ].forEach((photo) => {
      if (!photo.file) {
        body.append('ExistingPhotoUrls', photo.path);
      } else {
        body.append('ExistingPhotoUrls', null);
        body.append('NewPhotos', photo.file);
      }
    });
    try {
      const response = await TourProviderAPI.updateActivity(data.id, body);
      dispatch(
        notify({
          message: 'The activity has been updated successfully!',
          type: 'success',
        }),
      );
      return response.data.value;
    } catch (err) {
      dispatch(
        notify({
          message: `Something went wrong, the activity could not be updated. ${err.response.data.notificationMessage || ''}`,
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const createSchedules = createAsyncThunk(
  'activities/createScheduleStatus',
  async ({ activityId, schedule }, { dispatch }) => {
    try {
      const { data } = await ScheduleAPI.createSchedule(activityId, schedule);
      dispatch(
        notify({
          message: 'The activity schedule has been added successfully!',
          type: 'success',
        }),
      );
      history.push(`/dashboard/schedule/${activityId}`);
      return {
        id: activityId,
        schedule: data.value,
      };
    } catch (err) {
      dispatch(
        notify({
          message: 'Something went wrong and the schedule could not be added.',
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const updateSchedule = createAsyncThunk(
  'activities/updateScheduleStatus',
  async (data, { dispatch }) => {
    try {
      const response = await ScheduleAPI.updateSchedule(
        data.activityId,
        data.scheduleId,
        data.body,
      );
      dispatch(
        notify({
          message: 'The activity schedule has been updated successfully!',
          type: 'success',
        }),
      );
      return {
        id: data.activityId,
        schedule: response.data.value,
      };
    } catch (err) {
      dispatch(
        notify({
          message:
            'Something went wrong and the schedule could not be updated.',
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const deleteSchedule = createAsyncThunk(
  'activities/deleteScheduleStatus',
  async (data, { dispatch }) => {
    try {
      const response = await ScheduleAPI.deleteSchedule(
        data.activityId,
        data.scheduleId,
      );
      dispatch(
        notify({
          message: 'The activity schedule has been deleted successfully!',
          type: 'success',
        }),
      );
      return {
        id: data.activityId,
        schedules: response.data.value,
      };
    } catch (err) {
      dispatch(
        notify({
          message:
            'Something went wrong and the schedule could not be deleted.',
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const deletePaidExtra = createAsyncThunk(
  'activities/deletePaidExtraStatus',
  async (data, { dispatch }) => {
    try {
      const response = await TourProviderAPI.deletePaidExtra(
        data.activityId,
        data.paidExtraId,
      );
      dispatch(
        notify({
          message: 'The activity paid extra has been deleted successfully!',
          type: 'success',
        }),
      );
      return {
        id: data.activityId,
        paidExtras: response.data.value,
      };
    } catch (err) {
      dispatch(
        notify({
          message:
            'Something went wrong and the paid extra could not be deleted.',
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const addPaidExtra = createAsyncThunk(
  'activities/deletePaidExtraStatus',
  async ({ activityId, body }, { dispatch }) => {
    try {
      const response = await TourProviderAPI.addPaidExtra(activityId, [body]);
      dispatch(
        notify({
          message: 'The activity paid extra has been added successfully!',
          type: 'success',
        }),
      );
      return {
        id: activityId,
        paidExtras: response.data.value,
      };
    } catch (err) {
      dispatch(
        notify({
          message:
            'Something went wrong and the paid extra could not be added.',
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const fetchBookings = createAsyncThunk(
  'activities/fetchBookingsStatus',
  async ({ activityId, scheduleId }, { dispatch }) => {
    try {
      const { data } = await ScheduleAPI.fetchBookingsByScheduleId(
        activityId,
        scheduleId,
      );
      return data.value;
    } catch (err) {
      console.log(err);
      dispatch(
        notify({
          message: 'Something went wrong while fetching the bookings.',
          type: 'danger',
        }),
      );
      throw err;
    }
  },
);

export const createCustomPrivateTrip = createAsyncThunk(
  'activities/createCustomPrivateTripStatus',
  async (activityId, { dispatch }) => {
    try {
      const { data } = await TourProviderAPI.duplicateActivity(activityId);
      dispatch(
        notify({
          message:
            'The custom experience has been successfully created. Please edit it below.',
          type: 'success',
        }),
      );
      history.push(`/dashboard/edit/${data.value}`);
      return data.value;
    } catch (err) {
      dispatch(
        notify({
          message:
            'Something went wrong and the custom experience could not be created.',
          type: 'danger',
        }),
      );
    }
  },
);

export const fetchReport = createAsyncThunk(
  'activities/fetchReportStatus',
  async (body, { dispatch }) => {
    try {
      const response = await ActivitiesAPI.fetchReport(body);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `report-${body.startDate}-${body.endDate}.csv`);
      document.body.appendChild(link);
      link.click();
      return true;
    } catch (err) {
      dispatch(
        notify({
          message:
            "Generating report failed.",
          type: 'danger',
        }),
      );
    }
  },
);
