import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { suggestionType } from '../../helpers/constants';
import * as effects from './searchEffects';

const searchAdapter = createEntityAdapter();

const { actions, reducer } = createSlice({
  name: 'search',
  initialState: searchAdapter.getInitialState({
    suggestions: [],
    loading: {
      suggestions: 'idle',
    },
    filters: {},
    places: [],
    term: '',
  }),
  reducers: {
    setSearchTerm: (state, action) => {
      state.term = action.payload;
    },
    setFilters: (state, action) => {
      state.filters[action.payload.filter] = action.payload.value;
    },
  },
  extraReducers: {
    [effects.fetchSearchSuggestions.pending]: (state) => {
      if (state.loading.suggestions !== 'pending') {
        state.loading.suggestions = 'pending';
      }
    },
    [effects.fetchSearchSuggestions.fulfilled]: (state, action) => {
      if (state.loading.suggestions === 'pending') {
        state.suggestions = action.payload.map((suggestion) => ({
          ...suggestion,
          name: suggestion.itemType === suggestionType.LOCATION
            ? JSON.parse(suggestion.name) : suggestion.name,
        }));
        state.loading.suggestions = 'idle';
      }
    },
    [effects.fetchSearchSuggestions.rejected]: (state, action) => {
      state.loading.suggestions = 'idle';
    },
    [effects.fetchSearchPlaces.pending]: (state, action) => {
    },
    [effects.fetchSearchPlaces.fulfilled]: (state, action) => {
      console.log(action);
      state.places = action.payload;
    },
    [effects.fetchSearchPlaces.rejected]: (state, action) => {
    },
  },
});

export const { setSearchTerm, setFilters } = actions;

export default reducer;
