import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as effects from './tourProvidersEffects';

const tourProvidersAdapter = createEntityAdapter();

const { actions, reducer } = createSlice({
  name: 'tourProviders',
  initialState: tourProvidersAdapter.getInitialState({
    loading: {
      search: 'idle',
      indexing: 'idle',
    },
  }),
  reducers: {},
  extraReducers: {
    [effects.search.pending]: (state) => {
      if (state.loading.search !== 'pending') {
        state.loading.search = 'pending';
      }
    },
    [effects.search.fulfilled]: (state, action) => {
      if (state.loading.search === 'pending') {
        tourProvidersAdapter.setAll(state, action.payload);
        state.loading.search = 'done';
      }
    },
    [effects.search.rejected]: (state) => {
      state.loading.search = 'idle';
    },
    [effects.indexContent.pending]: (state) => {
      if (state.loading.indexing !== 'pending') {
        state.loading.indexing = 'pending';
      }
    },
    [effects.indexContent.fulfilled]: (state, action) => {
      if (state.loading.indexing === 'pending') {
        state.loading.indexing = 'idle';
      }
    },
    [effects.indexContent.rejected]: (state) => {
      state.loading.indexing = 'idle';
    },
  },
});

export default reducer;
