import { createAsyncThunk } from '@reduxjs/toolkit';
import TourProviderAPI from '../../api/TourProviderAPI';
import IndexAPI from '../../api/IndexAPI';
import { notify } from '../notifications/notificationsEffects';

export const search = createAsyncThunk(
  'tourProviders/searchStatus',
  async (body, { dispatch }) => {
    const { data } = await TourProviderAPI.search(body);
    return data.value.items;
  },
);

export const indexContent = createAsyncThunk(
  'tourProviders/indexContentStatus',
  async (type, { dispatch }) => {
    try {
      const response = await IndexAPI.indexContent(type);
      dispatch(
        notify({
          message:
            "Content indexing finished successfully!",
          type: 'success',
        }),
      );
      return response.data;
    } catch (err) {
      dispatch(
        notify({
          message:
            "Oups, something went wrong and content indexing failed!",
          type: 'danger',
        }),
      );
      console.log(err);
    }
  }
)