import API from '../utils/API';
import axios from 'axios';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const updateReview = (id, body) =>
  API.put(`/reviews/${id}`, body);

const validateReviewRequest = (guestEmailToken, rating) =>
  API.post(`/reviews/validate-request`, { guestEmailToken, rating }, {
    cancelToken: source.token
  });

const cancelValidateRequest = (msg) => source.cancel(msg);

export default {
  updateReview,
  validateReviewRequest,
  cancelValidateRequest
};
