import React from 'react';
import Loader from 'react-loader-spinner';

const SectionLoader = (props) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      color: props.color || 'black',
      fontWeight: 600,
    }}
  >
    <Loader type='Rings' color={props.color || '#000000'} height={60} width={60} />
    <span>{props.label}</span>
  </div>
);

export default SectionLoader;
