import API from '../utils/API';

const fetchSuggestions = (body) => API.post(
  '/search/autocomplete',
  body,
);

const fetchExperiences = (filters) => API.post(
  '/search/activities',
  filters,
);

const fetchUpcomingExperiences = (filters) => API.post(
  '/search/upcoming-experiences',
  filters,
);

export default {
  fetchSuggestions,
  fetchExperiences,
  fetchUpcomingExperiences,
};
