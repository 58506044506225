import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as effects from './exploreEffects';

const exploreAdapter = createEntityAdapter({
  selectId: (activity) => activity.shortId,
});

const { actions, reducer } = createSlice({
  name: 'explore',
  initialState: exploreAdapter.getInitialState({
    entities: {},
    ids: [],
    loading: {
      search: 'idle',
      activity: 'idle',
      fetchPopularExperiences: 'idle',
      fetchUpcomingExperiences: 'idle',
    },
    popular: [],
    upcoming: [],
    stickyFilters: false,
    triggeredFilter: '',
    search: {
      term: '',
      filters: {
        countryDestinationId: process.env.REACT_APP_KSA_ID,
        location: {
          label: '',
          value: '',
        },
        tagIds: [],
        minPrice: 0,
        maxPrice: 10000,
      },
      meta: {
        nextPage: 0,
        remaining: 0,
        total: 0,
        scheduleFiltering: 1,
      },
    },
  }),
  reducers: {
    setSearchTerm: (state, action) => {
      state.search.term = action.payload;
    },
    clearFilters: (state, action) => {
      state.search.filters.location = {};
      state.search.filters.tagIds = [];
      state.loading.search = 'idle';
    },
    setFilters: (state, action) => {
      state.search.filters[action.payload.filter] = action.payload.value;
    },
    setStickyFilters: (state, action) => {
      state.stickyFilters = action.payload;
    },
    setTriggeredFilter: (state, action) => {
      state.triggeredFilter = action.payload;
    },
  },
  extraReducers: {
    [effects.fetchExperiencesByProviderId.pending]: (state, action) => {
      if (state.loading.search === 'idle') {
        state.loading.search = 'pending';
        exploreAdapter.setAll(state, []);
        state.loading.activity = 'idle';
      }
    },
    [effects.fetchExperiencesByProviderId.fulfilled]: (state, action) => {
      if (state.loading.search === 'pending') {
          exploreAdapter.setAll(state, action.payload.items);
        state.loading.search = 'idle';
      }
    },
    [effects.fetchExperiencesByProviderId.rejected]: (state) => {
      state.loading.search = 'idle';
    },
    [effects.fetchExperiencesByTagIds.pending]: (state, action) => {
      if (state.loading.search === 'idle') {
        state.loading.search = 'pending';
        exploreAdapter.setAll(state, []);
        state.loading.activity = 'idle';
      }
    },
    [effects.fetchExperiencesByTagIds.fulfilled]: (state, action) => {
      if (state.loading.search === 'pending') {
          exploreAdapter.setAll(state, action.payload.items);
        state.loading.search = 'idle';
      }
    },
    [effects.fetchExperiencesByTagIds.rejected]: (state) => {
      state.loading.search = 'idle';
    },
    [effects.fetchPopularExperiences.pending]: (state, action) => {
      if (state.loading.fetchPopularExperiences === 'idle') {
        state.loading.fetchPopularExperiences = 'pending';
        state.loading.activity = 'idle';
      }
    },
    [effects.fetchPopularExperiences.fulfilled]: (state, action) => {
      if (state.loading.fetchPopularExperiences === 'pending') {
        state.popular = action.payload.items;
        state.loading.fetchPopularExperiences = 'idle';
      }
    },
    [effects.fetchPopularExperiences.rejected]: (state) => {
      state.loading.fetchPopularExperiences = 'idle';
    },
    [effects.fetchUpcomingExperiences.pending]: (state, action) => {
      if (state.loading.fetchUpcomingExperiences === 'idle') {
        state.loading.fetchUpcomingExperiences = 'pending';
        state.loading.activity = 'idle';
      }
    },
    [effects.fetchUpcomingExperiences.fulfilled]: (state, action) => {
      if (state.loading.fetchUpcomingExperiences === 'pending') {
        state.upcoming = action.payload.items;
        state.loading.fetchUpcomingExperiences = 'idle';
      }
    },
    [effects.fetchUpcomingExperiences.rejected]: (state) => {
      state.loading.fetchUpcomingExperiences = 'idle';
    },
    [effects.search.pending]: (state, action) => {
      if (state.loading.search !== 'pending') {
        state.loading.search = 'pending';
        state.loading.activity = 'idle';
      }
    },
    [effects.search.fulfilled]: (state, action) => {
      if (state.loading.search === 'pending') {
        if (!action.meta.arg.page && action.meta.arg.scheduleFiltering === 1) {
          exploreAdapter.setAll(state, action.payload.items);
          state.search.meta.nextPage = 1;
        } else {
          exploreAdapter.addMany(state, action.payload.items);
          state.search.meta.nextPage++;
        }
        state.search.meta.remaining = action.payload.remaining;
        state.search.meta.total = action.payload.total;
        state.search.meta.scheduleFiltering = action.meta.arg.scheduleFiltering;
        state.loading.search = 'done';
      }
    },
    [effects.search.rejected]: (state) => {
      state.loading.search = 'done';
    },
    [effects.fetchActivity.pending]: (state) => {
      if (state.loading.activity !== 'pending') {
        state.loading.activity = 'pending';
      }
    },
    [effects.fetchActivity.fulfilled]: (state, action) => {
      if (state.loading.activity === 'pending') {
        exploreAdapter.upsertOne(state, {
          ...action.payload,
          paidExtras: action.payload.paidExtras.reduce(
            (extras, extra) => ({ ...extras, [extra.ticketTypeId]: extra }),
            {},
          ),
        });
        state.loading.activity = 'done';
      }
    },
    [effects.fetchActivity.rejected]: (state, action) => {
      state.loading.activity = 'failed';
    },
  },
});

export const { setSearchTerm, setFilters, setStickyFilters, setTriggeredFilter, clearFilters } = actions;

export default reducer;
