import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import RegularFont from '../../assets/fonts/Montserrat-Regular.ttf';
import BoldFont from '../../assets/fonts/Montserrat-Bold.ttf';
import Logo from '../../assets/images/logo_white.png';

const styles = StyleSheet.create({
  page: { 
    backgroundColor: 'white',
    fontSize: 14,
    fontFamily: 'Montserrat',
    paddingBottom: 30,
  },
  header: { 
    backgroundColor: 'black',
    color: 'white',
    padding: 30,
    width: '100%',
    marginBottom: 15,
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  },
  headerText: {
    flex: 1,
    padding: '0px 30px'
  },
  booking: {
    width: '100%',
    padding: '15px 30px',
  },
  client: {
    fontSize: 14,
    marginBottom: 4,
    fontWeight: 'bold',
  },
  clientContact: {
    fontSize: 14,
    marginBottom: 8,
  },
  guestDetails: {
    fontSize: 14,
    marginBottom: 4,
  },
  logo: { width: 60, height: 60 },
  subtitle: { fontSize: 14 },
  title: { fontSize: 24, fontWeight: 'bold', width: '100%' },
  date: { fontSize: 14},
  pageNumber: {
    position: 'absolute',
    bottom: 10,
    fontSize: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
  }
});

Font.register({ family: 'Montserrat', fonts: [
  { src: RegularFont },
  { src: BoldFont, fontWeight: 'bold' },
]});

Font.registerHyphenationCallback(word => [word]);

const BookingsDetailsList = ({ title, date, avatar, bookings }) => {
  return (
  <Document>
    <Page style={styles.page} size="A4">
      <View style={styles.header}>
        <Image source={Logo} allowDangerousPaths={true} style={styles.logo} />
        <View style={styles.headerText}>
          <Text style={styles.subtitle}>BOOKINGS LIST FOR</Text>
          <Text style={styles.title}>{title}</Text>
          <Text style={styles.date}>{date}</Text>
        </View>
      </View>
      {bookings.map((booking, index) => (
        <View style={{...styles.booking, ...(index % 2 ? { backgroundColor: '#e9ecef'} : {})}}>
          <Text style={styles.client}>{`#${booking.confirmationNumber} ${booking.bookedBy.firstName} ${booking.bookedBy.lastName}`}</Text>
          <Text style={styles.clientContact}>{`${booking.bookedBy.emailAddress} ${booking.bookedBy.phoneNumber.replace(/ /g, '')}`}</Text>
          {booking.guests.map((guest, index) => (
            <View wrap={false}>
              <View style={{ display: 'flex', flexDirection: 'row', marginTop: 4, fontSize: 14 }}>
                <Text style={{ fontWeight: 'bold' }}>
                  {`${guest.firstName} ${guest.lastName}`}
                </Text>
                <Text style={{ marginLeft: 5 }}>{guest.gender ? `(${guest.gender})` : ''}</Text>
              </View>
              <Text style={styles.guestDetails}>
                {`${guest.phoneNumber || ''} ${guest.phoneNumber ? '/' : ''} ${guest.emailAddress} ${guest.idType ? `/ ${guest.idType.replace(/_/g, ' ')}: ${guest.idNumber}` : ''}`}
              </Text>
            </View>
          ))}
        </View>
      ))}
      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
    </Page>
  </Document>
)};

export default BookingsDetailsList;

