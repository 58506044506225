import API, { authenticate } from '../utils/API';

const indexContent = (indexableContentType) =>
  API.post(`/index`, { indexableContentType }, {
    ...authenticate(),
  });

export default {
  indexContent,
};
