import API, { authenticate } from '../utils/API';

const login = (body) => API.post('/accounts/login', body);

const loginAs = (email, token) =>
  API.post('/accounts/login/admin', { email }, { ...authenticate(token) });

const register = (body) => API.post('/accounts/register', body);

const fetchDetails = (token) => API.get('/accounts/details', { ...authenticate(token) });

const updateDetails = (body) =>
  API.put('/application-users', body, {
    ...authenticate(),
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  });

const fetchActivities = () =>
  API.get('/activities/user', { ...authenticate() });

const fetchActivityStatus = (id) =>
  API.get(`/activities/${id}/guests/status`, { ...authenticate() });

const addActivityToBucketList = (id) =>
  API.post(`/activities/${id}/guests`, {}, { ...authenticate() });

const removeActivityFromBucketList = (id) =>
  API.delete(`/activities/${id}/guests`, { ...authenticate(), data: {} });

const loginBucketListActivity = (body) =>
  API.post('/accounts/login/activity', body);

const registerBucketListActivity = (body) =>
  API.post('/accounts/register/activity', body);

const checkIfAccountExists = (email) => API.get(`/accounts/exists/${email}`);

const requestPasswordReset = (body) =>
  API.post('/accounts/password-reset-token', body);

const resetPassword = (body) => API.post('/accounts/reset-password', body);

export default {
  login,
  loginAs,
  register,
  fetchDetails,
  updateDetails,
  fetchActivities,
  fetchActivityStatus,
  addActivityToBucketList,
  removeActivityFromBucketList,
  loginBucketListActivity,
  registerBucketListActivity,
  checkIfAccountExists,
  requestPasswordReset,
  resetPassword,
};
