import API, { authenticate } from '../utils/API';

export const search = (body) => API.post('/search/activities', body);

export const fetchActivityByShortId = (shortId) =>
  API.get(`/activities/short-id/${shortId}`);

export const fetchActivityBySlug = (slug) =>
  API.get(`/activities/slug/${slug}`);

export const fetchReviewsByActivityId = (activityId) =>
  API.post(`/search/reviews`, {
    status: 1,
    activityId,
    take: 10000,
    from: 0
  });

export const fetchReport = (body) =>
  API.post('/activities/reports', body, {
    ...authenticate(),
    transformResponse: (res) => res,
    responseType: 'application/stream'
  });

export default {
  search,
  fetchActivityByShortId,
  fetchActivityBySlug,
  fetchReviewsByActivityId,
  fetchReport,
};
