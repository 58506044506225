import API, { authenticate } from '../utils/API';

const createSchedule = (activityId, schedule) =>
  API.post(`/activities/${activityId}/schedules`, schedule, {
    ...authenticate(),
  });

const updateSchedule = (activityId, scheduleId, body) =>
  API.put(`/activities/${activityId}/schedules/${scheduleId}`, body, {
    ...authenticate(),
  });

const deleteSchedule = (activityId, scheduleId) =>
  API.delete(`/activities/${activityId}/schedules/${scheduleId}`, {
    ...authenticate(),
    data: {},
  });

const updateScheduleTicket = (activityId, scheduleId, ticketTypeId, body) =>
  API.put(
    `/activities/${activityId}/schedules/${scheduleId}/ticket-types/${ticketTypeId}`,
    body,
    {
      ...authenticate(),
    },
  );

const fetchBookingsByScheduleId = (activityId, scheduleId) =>
  API.get(`/activities/${activityId}/schedules/${scheduleId}/bookings`, {
    ...authenticate(),
  });

export default {
  createSchedule,
  updateSchedule,
  deleteSchedule,
  updateScheduleTicket,
  fetchBookingsByScheduleId,
};
