import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as effects from './bookingsEffect';

const bookingsAdapter = createEntityAdapter();

const { actions, reducer } = createSlice({
  name: 'bookings',
  initialState: bookingsAdapter.getInitialState({
    checkout: {
      activity: null,
      schedule: null,
      adultTickets: 0,
      childTickets: 0,
      extras: [],
      travelers: [],
    },
    loading: {
      fetchBookings: 'idle',
      fetchBookingById: 'idle',
      cancelBookingById: 'idle',
      handleCheckout: 'idle',
    },
  }),
  reducers: {
    updateCheckout: (state, action) => {
      state.checkout = { ...state.checkout, ...action.payload };
      localStorage.setItem('checkout', JSON.stringify(state.checkout));
    },
    clearCheckout: (state) => {
      state.checkout = {};
      localStorage.removeItem('checkout');
    },
  },
  extraReducers: {
    [effects.fetchBookings.pending]: (state) => {
      if (state.loading.fetchBookings !== 'pending') {
        state.loading.fetchBookings = 'pending';
      }
    },
    [effects.fetchBookings.fulfilled]: (state, action) => {
      if (state.loading.fetchBookings === 'pending') {
        bookingsAdapter.setAll(state, action.payload);
        state.loading.fetchBookings = 'done';
      }
    },
    [effects.fetchBookingById.pending]: (state) => {
      if (state.loading.fetchBookingById !== 'pending') {
        state.loading.fetchBookingById = 'pending';
      }
    },
    [effects.fetchBookingById.fulfilled]: (state, action) => {
      if (state.loading.fetchBookingById === 'pending') {
        bookingsAdapter.upsertOne(state, action.payload);
        state.loading.fetchBookingById = 'done';
      }
    },
    [effects.cancelBookingById.pending]: (state) => {
      if (state.loading.cancelBookingById !== 'pending') {
        state.loading.cancelBookingById = 'pending';
      }
    },
    [effects.cancelBookingById.fulfilled]: (state, action) => {
      if (state.loading.cancelBookingById === 'pending') {
        bookingsAdapter.upsertOne(state, action.payload);
        state.loading.cancelBookingById = 'done';
      }
    },
    [effects.cancelBookingById.rejected]: (state) => {
      state.loading.cancelBookingById = 'idle';
    },
    [effects.handleCheckout.pending]: (state) => {
      if (state.loading.handleCheckout !== 'pending') {
        state.loading.handleCheckout = 'pending';
      }
    },
    [effects.handleCheckout.fulfilled]: (state, action) => {
      if (state.loading.handleCheckout === 'pending') {
        bookingsAdapter.addOne(state, action.payload);
        state.checkout = {};
        localStorage.removeItem('checkout');
        state.loading.handleCheckout = 'idle';
      }
    },
    [effects.handleCheckout.rejected]: (state) => {
      state.loading.handleCheckout = 'idle';
    },
  },
});

export const { updateCheckout, clearCheckout } = actions;

export default reducer;
