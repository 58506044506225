import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDay, startOfDay, endOfDay, endOfMonth, formatISO, format, fromUnixTime, addDays, addWeeks, endOfWeek } from 'date-fns';
import SearchAPI from '../../api/SearchAPI';

export const initCalendar = createAsyncThunk(
  'upcoming/initCalendar',
  async () => {
    try {
      const startDateTime = startOfDay(new Date()); 
      const endDateTime = endOfWeek(addWeeks(startDateTime, 11), {
        weekStartsOn: getDay(startDateTime),
      });
      const response = await SearchAPI.fetchUpcomingExperiences({
        take: 999,
        scheduleStartDateTime: formatISO(startDateTime),
        scheduleEndDateTime: formatISO(endDateTime),
      });
      return {
        ...response.data.value,
        displayStartDate: format(startDateTime, 't'),
        displayEndDate: format(endDateTime, 't'),
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
);

export const fetchExperiencesByDate = createAsyncThunk(
  'upcoming/fetchExperiencesByDate',
  async (date) => {
    try {
      const scheduleStartDateTime = formatISO(startOfDay(date));
      const scheduleEndDateTime = formatISO(endOfDay(date));
      const response = await SearchAPI.fetchUpcomingExperiences({
        take: 999,
        scheduleStartDateTime,
        scheduleEndDateTime,
      });
      return response.data.value;
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
);

export const fetchMoreExperiences = createAsyncThunk(
  'upcoming/fetchMoreExperiences',
  async (_, { getState }) => {
    try {
      const { endDate } = getState().upcoming.display;
      const startDateTime = startOfDay(addDays(fromUnixTime(endDate), 1)); 
      const endDateTime = endOfDay(addWeeks(startDateTime, 8));
      const response = await SearchAPI.fetchUpcomingExperiences({
        take: 999,
        scheduleStartDateTime: formatISO(startDateTime),
        scheduleEndDateTime: formatISO(endDateTime),
      });
      return {
        ...response.data.value,
        displayEndDate: format(endDateTime, 't'),
      };
    } catch (err) {
      console.log(err);
      throw err;
    }
  },
);
