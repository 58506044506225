import API, { authenticate } from '../utils/API';

const register = (body) => API.post('/accounts/tour-provider/register', body);

const fetchDetails = () =>
  API.get('/tour-providers/current', { ...authenticate() });

const updateDetails = (data) =>
  API.put('/tour-providers', data, {
    ...authenticate(),
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  });

const fetchActivities = () =>
  API.get('/activities/tour-provider', { ...authenticate() });

const fetchActivityById = (id) =>
  API.get(`/activities/${id}`, { ...authenticate() });

const removeActivity = (id) =>
  API.delete(`/activities/${id}`, { ...authenticate() });

const addActivity = (data) =>
  API.post('/activities', data, {
    ...authenticate(),
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  });

const duplicateActivity = (id) =>
  API.post(`/activities/duplicate-activity/${id}`, {}, { ...authenticate() });

const updateActivity = (id, data) =>
  API.put(`/activities/${id}`, data, {
    ...authenticate(),
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data',
  });

const deletePaidExtra = (activityId, paidExtraId) =>
  API.delete(`/activities/${activityId}/paid-extras/${paidExtraId}`, {
    ...authenticate(),
    data: {},
  });

const addPaidExtra = (activityId, data) =>
  API.post(`/activities/${activityId}/paid-extras`, data, {
    ...authenticate(),
  });

const search = (data) => API.post('/search/tour-providers', data);

export default {
  register,
  fetchDetails,
  updateDetails,
  fetchActivities,
  fetchActivityById,
  removeActivity,
  addActivity,
  duplicateActivity,
  updateActivity,
  addPaidExtra,
  deletePaidExtra,
  search,
};
