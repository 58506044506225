import { createSlice, createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { chain, uniq, uniqBy } from 'lodash';
import { format, isSameDay, isToday, isTomorrow, startOfDay } from 'date-fns';
import * as effects from './upcomingEffects';

const upcomingAdapter = createEntityAdapter();
const globalizedUpcomingSelectors = upcomingAdapter.getSelectors(
  (state) => state.upcoming,
);

const { actions, reducer } = createSlice({
  name: 'upcoming',
  initialState: upcomingAdapter.getInitialState({
    fetching: 'idle',
    initializing: 'idle',
    display: {
      currentDate: null, 
      startDate: null,
      endDate: null,
    },
  }),
  reducers: {},
  extraReducers: {
    [effects.initCalendar.pending]: (state, action) => {
      if (state.initializing === 'idle') {
        state.initializing = 'pending';
      }
    },
    [effects.initCalendar.fulfilled]: (state, action) => {
      if (state.initializing === 'pending') {
        upcomingAdapter.setAll(state, action.payload.items);
        state.display = {
          currentDate: action.payload.displayStartDate,
          startDate: action.payload.displayStartDate,
          endDate: action.payload.displayEndDate,
        };
        state.initializing = 'done';
      }
    },
    [effects.initCalendar.rejected]: (state) => {
      state.initializing = 'failed';
    },
    [effects.fetchMoreExperiences.pending]: (state, action) => {
      if (state.fetching === 'idle') {
        state.fetching = 'pending';
      }
    },
    [effects.fetchMoreExperiences.fulfilled]: (state, action) => {
      if (state.fetching === 'pending') {
        upcomingAdapter.upsertMany(state, action.payload.items);
        state.display.endDate = action.payload.displayEndDate;
        state.fetching = 'idle';
      }
    },
    [effects.fetchMoreExperiences.rejected]: (state) => {
      state.fetching = 'failed';
    },
    [effects.fetchExperiencesByDate.pending]: (state, action) => {
      if (state.fetching === 'idle') {
        state.fetching = 'pending';
      }
    },
    [effects.fetchExperiencesByDate.fulfilled]: (state, action) => {
      if (state.fetching === 'pending') {
        upcomingAdapter.upsertMany(state, action.payload.items);
        state.fetching = 'idle';
      }
    },
    [effects.fetchExperiencesByDate.rejected]: (state) => {
      state.fetching = 'failed';
    },
  },
});

export const getFormattedExperiences = createSelector(
  globalizedUpcomingSelectors.selectAll,
  (experiences) => chain(experiences)
    .groupBy((experience) =>  format(startOfDay(new Date(experience.scheduleStartDateTime)), 't'))
    .mapValues((experiencesByDate) => ({
      experiences: uniqBy(experiencesByDate, 'shortId'),
      locations: uniq(experiencesByDate.map((experience) => experience.destination?.localityName)),
    }))
    .value(),
);

export const getExperiencesByDate = (date) => createSelector(
  globalizedUpcomingSelectors.selectAll,
  (experiences) => experiences.filter((experience) => isSameDay(new Date(date), new Date(experience.scheduleStartDateTime)))
);

/* export const getFormattedExperiences = createSelector(
  globalizedUpcomingSelectors.selectAll,
  (experiences) => chain(experiences)
    .groupBy((experience) =>  format(startOfDay(new Date(experience.scheduleStartDateTime)), 't'))
    .toPairs()
    .map(([timestamp, data]) => ({
      title: dateFormatter(fromUnixTime(parseInt(timestamp))),
      timestamp,
      data,
      // data: orderBy(data, ['created'], ['asc']),
    }))
    .orderBy(['timestamp'], ['asc'])
    .value(),
); */

const dateFormatter = (date) => {
  if (isToday(date)) return 'Today';
  if (isTomorrow(date)) return 'Tomorrow';
  return format(date, 'd MMMM y');
};


export default reducer;
