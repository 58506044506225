import axios from 'axios';

const placeSearch = (searchTerm) => axios.get('/api/places', {
  params: { searchTerm },
});

const requestPhoto = (photoRef) => axios.get('/api/photo', {
  params: { photoRef },
});

export default {
  placeSearch,
  requestPhoto,
};
