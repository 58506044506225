import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as effects from './activitiesEffects';

const activitiesAdapter = createEntityAdapter();

const { actions, reducer } = createSlice({
  name: 'activities',
  initialState: activitiesAdapter.getInitialState({
    entities: {},
    ids: [],
    bookings: [],
    loading: {
      fetchAll: 'idle',
      fetchById: 'idle',
      handleActivity: 'idle',
      visibilityToggle: 'idle',
      bookings: 'idle',
      createSchedules: 'idle',
      deleteSchedule: 'idle',
      report: 'idle'
    },
  }),
  reducers: {
    activityUpdated: activitiesAdapter.updateOne,
  },
  extraReducers: {
    [effects.fetchActivities.pending]: (state, action) => {
      if (state.loading.fetchAll === 'idle') {
        state.loading.fetchAll = 'pending';
      }
    },
    [effects.fetchActivities.fulfilled]: (state, action) => {
      if (state.loading.fetchAll === 'pending') {
        activitiesAdapter.setAll(state, action.payload);
        state.loading.fetchAll = 'idle';
      }
    },
    [effects.fetchActivities.rejected]: (state) => {
      state.loading.fetchAll = 'idle';
      activitiesAdapter.setAll(state, []);
    },
    [effects.fetchActivityById.pending]: (state) => {
      if (state.loading.fetchById !== 'pending') {
        state.loading.fetchById = 'pending';
      }
    },
    [effects.fetchActivityById.fulfilled]: (state, action) => {
      if (state.loading.fetchById === 'pending') {
        activitiesAdapter.upsertOne(state, {
          ...action.payload,
          paidExtras: action.payload.paidExtras.reduce(
            (extras, extra) => ({ ...extras, [extra.ticketTypeId]: extra }),
            {},
          ),
        });
        state.loading.fetchById = 'done';
      }
    },
    [effects.fetchActivityById.rejected]: (state, action) => {
      state.loading.fetchById = 'done';
      activitiesAdapter.removeOne(state, action.meta.arg);
    },
    [effects.removeActivity.pending]: (state) => {
      if (state.loading.removeActivity === 'idle') {
        state.loading.removeActivity = 'pending';
      }
    },
    [effects.removeActivity.fulfilled]: (state, action) => {
      if (state.loading.removeActivity === 'pending') {
        activitiesAdapter.removeOne(state, action.payload);
        state.loading.removeActivity = 'done';
      }
    },
    // [effects.removeActivity.rejected]: (state, action) => {
    //   state.loading.removeActivity = 'done';
    //   activitiesAdapter.removeOne(state, action.meta.arg);
    // },
    [effects.setActivityVisibilityStatus.pending]: (state) => {
      if (state.loading.visibilityToggle !== 'pending') {
        state.loading.visibilityToggle = 'pending';
      }
    },
    [effects.setActivityVisibilityStatus.fulfilled]: (state, action) => {
      if (state.loading.visibilityToggle === 'pending') {
        activitiesAdapter.upsertOne(state, action.payload);
        state.loading.visibilityToggle = 'idle';
      }
    },
    [effects.addActivity.pending]: (state) => {
      if (state.loading.handleActivity !== 'pending') {
        state.loading.handleActivity = 'pending';
      }
    },
    [effects.addActivity.fulfilled]: (state, action) => {
      if (state.loading.handleActivity === 'pending') {
        activitiesAdapter.addOne(state, action.payload);
        state.loading.handleActivity = 'done';
      }
    },
    [effects.addActivity.rejected]: (state, action) => {
      state.loading.handleActivity = 'done';
    },
    [effects.updateActivity.pending]: (state) => {
      if (state.loading.handleActivity !== 'pending') {
        state.loading.handleActivity = 'pending';
      }
    },
    [effects.updateActivity.fulfilled]: (state, action) => {
      if (state.loading.handleActivity === 'pending') {
        activitiesAdapter.upsertOne(state, action.payload);
        state.loading.handleActivity = 'idle';
      }
    },
    [effects.updateActivity.rejected]: (state) => {
      state.loading.handleActivity = 'idle';
    },
    [effects.createSchedules.pending]: (state) => {
      if (state.loading.createSchedules !== 'pending') {
        state.loading.createSchedules = 'pending';
      }
    },
    [effects.createSchedules.fulfilled]: (state, action) => {
      if (state.loading.createSchedules === 'pending') {
        state.entities[action.payload.id].schedules.push(
          ...action.payload.schedule,
        );
        state.loading.createSchedules = 'done';
      }
    },
    [effects.updateSchedule.pending]: (state) => {
      if (state.loading.createSchedules !== 'pending') {
        state.loading.createSchedules = 'pending';
      }
    },
    [effects.updateSchedule.fulfilled]: (state, action) => {
      //TODO normalize schedules

      if (state.loading.createSchedules === 'pending') {
        state.entities[action.payload.id].schedules = state.entities[
          action.payload.id
        ].schedules.map((schedule) =>
          schedule.id === action.payload.schedule.id
            ? action.payload.schedule
            : schedule,
        );

        state.loading.createSchedules = 'done';
      }
    },
    [effects.deleteSchedule.pending]: (state) => {
      if (state.loading.deleteSchedule !== 'pending') {
        state.loading.deleteSchedule = 'pending';
      }
    },
    [effects.deleteSchedule.fulfilled]: (state, action) => {
      if (state.loading.deleteSchedule === 'pending') {
        state.entities[action.payload.id].schedules = action.payload.schedules;
        state.loading.deleteSchedule = 'done';
      }
    },
    [effects.deleteSchedule.rejected]: (state) => {
      state.loading.deleteSchedule = 'idle';
    },
    [effects.deletePaidExtra.fulfilled]: (state, action) => {
      state.entities[action.payload.id].paidExtras = action.payload.paidExtras;
    },
    [effects.fetchBookings.pending]: (state) => {
      if (state.loading.bookings !== 'pending') {
        state.loading.bookings = 'pending';
      }
    },
    [effects.fetchBookings.fulfilled]: (state, action) => {
      if (state.loading.bookings === 'pending') {
        state.loading.bookings = 'done';
        state.bookings = action.payload;
      }
    },
    [effects.fetchBookings.rejected]: (state) => {
      state.loading.bookings = 'idle';
      state.bookings = [];
    },
    [effects.createCustomPrivateTrip.pending]: (state) => {
      if (state.loading.createCustomPrivateTrip !== 'pending') {
        state.loading.createCustomPrivateTrip = 'pending';
      }
    },
    [effects.createCustomPrivateTrip.fulfilled]: (state, action) => {
      if (state.loading.createCustomPrivateTrip === 'pending') {
        state.loading.createCustomPrivateTrip = 'done';
      }
    },
    [effects.createCustomPrivateTrip.rejected]: (state) => {
      state.loading.createCustomPrivateTrip = 'idle';
    },
    [effects.fetchReport.pending]: (state) => {
      if (state.loading.report !== 'pending') {
        state.loading.report = 'pending';
      }
    },
    [effects.fetchReport.fulfilled]: (state, action) => {
      if (state.loading.report === 'pending') {
        state.loading.report = 'done';
      }
    },
    [effects.fetchReport.rejected]: (state) => {
      state.loading.report = 'idle';
    },
  },
});

export const { activityUpdated } = actions;

export default reducer;
