import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as effects from './userEffects';
import history from '../../utils/history';

const bucketAdapter = createEntityAdapter({
  selectId: (activity) => activity.shortId,
});

// TODO keep bucket list on separate key in slice or move to another slice
// or maybe dispatch on explore slice and keep only ids here
const { actions, reducer } = createSlice({
  name: 'user',
  initialState: bucketAdapter.getInitialState({
    details: {},
    checkout: {
      activity: null,
      schedule: null,
      adultTickets: 0,
      childTickets: 0,
      extras: [],
      travelers: [],
    },
    loading: {
      login: 'idle',
      loginAs: 'idle',
      register: 'idle',
      details: 'idle',
      registerProvider: 'idle',
      updateProviderDetails: 'idle',
      fetchActivities: 'idle',
      bucketAction: 'idle',
      handleBucketListActivity: 'idle',
      requestPasswordReset: 'idle',
      resetPassword: 'idle',
      logout: 'idle',
      handleActivityInquiry: 'idle',
      handleAvailabilityInquiry: 'idle',
    },
    isExistingAccount: false,
    destinations: [],
    currencies: [{
      id: 'SAR'
    }],
    tags: null,
  }),
  reducers: {
    logout: (state, action) => {
      state.loading.logout = 'pending';
      state.details = {};
      state.entities = {};
      state.isExistingAccount = false;
      state.ids = [];
      if (localStorage.getItem('superadmin')) {
        localStorage.setItem('token', localStorage.getItem('superadmin'));
        localStorage.setItem('role', 'superAdmin');
        localStorage.removeItem('superadmin');
        history.replace('/dashboard/admin');
        window.location.reload();
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
      }
      state.loading.login = 'idle';
    },
    onRedirect: (state) => {
      state.loading.register = 'idle';
      state.loading.login = 'idle';
      state.loading.loginAs = 'idle';
      state.loading.handleBucketListActivity = 'idle';
    },
    setExistingAccountStatus: (state, action) => {
      state.isExistingAccount = action.payload;
    },
  },
  extraReducers: {
    [effects.login.pending]: (state) => {
      if (state.loading.login !== 'pending') {
        state.loading.login = 'pending';
      }
    },
    [effects.login.fulfilled]: (state, action) => {
      if (state.loading.login === 'pending') {
        state.details = action.payload;
        state.loading.login = 'done';
      }
    },
    [effects.login.rejected]: (state) => {
      state.loading.login = 'idle';
      state.details = {};
    },
    [effects.loginAs.pending]: (state) => {
      if (state.loading.loginAs !== 'pending') {
        state.loading.loginAs = 'pending';
      }
    },
    [effects.loginAs.fulfilled]: (state, action) => {
      if (state.loading.loginAs === 'pending') {
        state.loading.loginAs = 'done';
        state.details = action.payload;
      }
    },
    [effects.loginAs.rejected]: (state) => {
      state.loading.loginAs = 'idle';
      state.details = {};
    },
    [effects.register.pending]: (state) => {
      if (state.loading.register !== 'pending') {
        state.loading.register = 'pending';
      }
    },
    [effects.register.fulfilled]: (state, action) => {
      if (state.loading.register === 'pending') {
        state.details = action.payload;
        state.loading.register = 'done';
      }
    },
    [effects.register.rejected]: (state) => {
      state.loading.register = 'idle';
      state.details = {};
    },
    [effects.fetchDetails.pending]: (state) => {
      if (state.loading.details === 'idle') {
        state.loading.details = 'pending';
      }
    },
    [effects.fetchDetails.fulfilled]: (state, action) => {
      if (state.loading.details === 'pending') {
        state.details = action.payload;
        state.loading.details = 'done';
      }
    },
    [effects.fetchDetails.rejected]: (state) => {
      state.loading.details = 'idle';
      state.details = {};
    },
    [effects.updateDetails.pending]: (state) => {
      if (state.loading.details !== 'pending') {
        state.loading.details = 'pending';
      }
    },
    [effects.updateDetails.fulfilled]: (state, action) => {
      if (state.loading.details === 'pending') {
        state.details = action.payload;
        state.loading.details = 'done';
      }
    },
    [effects.registerProvider.pending]: (state) => {
      if (state.loading.registerProvider !== 'pending') {
        state.loading.registerProvider = 'pending';
      }
    },
    [effects.registerProvider.fulfilled]: (state, action) => {
      if (state.loading.registerProvider === 'pending') {
        state.loading.registerProvider = 'done';
        state.details = action.payload;
      }
    },
    [effects.registerProvider.rejected]: (state) => {
      state.loading.registerProvider = 'idle';
      state.details = {};
    },
    [effects.updateProviderDetails.pending]: (state) => {
      if (state.loading.updateProviderDetails !== 'pending') {
        state.loading.updateProviderDetails = 'pending';
      }
    },
    [effects.updateProviderDetails.fulfilled]: (state, action) => {
      if (state.loading.updateProviderDetails === 'pending') {
        state.loading.updateProviderDetails = 'done';
        state.details = { ...state.details, ...action.payload };
      }
    },
    [effects.updateProviderDetails.rejected]: (state, action) => {
      state.loading.updateProviderDetails = 'idle';
      state.details = { ...state.details, ...action.payload };
    },
    [effects.fetchActivities.pending]: (state) => {
      if (state.loading.fetchActivities !== 'pending') {
        state.loading.fetchActivities = 'pending';
      }
    },
    [effects.fetchActivities.fulfilled]: (state, action) => {
      if (state.loading.fetchActivities === 'pending') {
        bucketAdapter.setAll(state, action.payload);
        state.loading.fetchActivities = 'done';
      }
    },
    [effects.fetchActivities.rejected]: (state) => {
      state.loading.fetchActivities = 'idle';
    },
    [effects.fetchActivityStatus.fulfilled]: (state, action) => {
      bucketAdapter.upsertOne(state, action.payload);
    },
    [effects.fetchActivityStatus.rejected]: (state, action) => {
      //todo update if status changed
    },
    [effects.addToBucketList.pending]: (state) => {
      if (state.loading.bucketAction !== 'pending')
        state.loading.bucketAction = 'pending';
    },
    [effects.addToBucketList.fulfilled]: (state, action) => {
      if (state.loading.bucketAction === 'pending') {
        state.loading.bucketAction = 'done';
        bucketAdapter.upsertOne(state, action.payload);
      }
    },
    [effects.addToBucketList.rejected]: (state) => {
      state.loading.bucketAction = 'idle';
    },
    [effects.removeFromBucketList.pending]: (state) => {
      if (state.loading.bucketAction !== 'pending')
        state.loading.bucketAction = 'pending';
    },
    [effects.removeFromBucketList.fulfilled]: (state, action) => {
      if (state.loading.bucketAction === 'pending') {
        state.loading.bucketAction = 'done';
        bucketAdapter.removeOne(state, action.payload);
      }
    },
    [effects.removeFromBucketList.rejected]: (state) => {
      state.loading.bucketAction = 'idle';
    },
    [effects.handleBucketListActivity.pending]: (state) => {
      if (state.loading.handleBucketListActivity !== 'pending') {
        state.loading.handleBucketListActivity = 'pending';
      }
    },
    [effects.handleBucketListActivity.fulfilled]: (state, action) => {
      if (state.loading.handleBucketListActivity === 'pending') {
        state.details = { ...action.payload.details, role: 'user' };
        bucketAdapter.upsertOne(state, action.payload.activity);
        state.loading.handleBucketListActivity = 'done';
      }
    },
    [effects.handleBucketListActivity.rejected]: (state) => {
      state.loading.handleBucketListActivity = 'idle';
    },
    [effects.checkIfAccountExists.fulfilled]: (state, action) => {
      state.isExistingAccount = action.payload;
    },
    [effects.checkIfAccountExists.rejected]: (state) => {
      state.isExistingAccount = false;
    },
    [effects.fetchCurrencies.fulfilled]: (state, action) => {
      state.currencies = action.payload;
    },
    [effects.fetchDestinations.fulfilled]: (state, action) => {
      state.destinations = action.payload;
    },
    [effects.fetchActivityTags.fulfilled]: (state, action) => {
      state.tags = action.payload.reduce((acc, tag) => ({
        ...acc,
        [tag.id]: tag,
      }), {});
    },
    [effects.requestPasswordReset.pending]: (state) => {
      if (state.loading.requestPasswordReset !== 'pending') {
        state.loading.requestPasswordReset = 'pending';
      }
    },
    [effects.requestPasswordReset.fulfilled]: (state, action) => {
      if (state.loading.requestPasswordReset === 'pending') {
        state.loading.requestPasswordReset = 'done';
      }
    },
    [effects.requestPasswordReset.rejected]: (state) => {
      state.loading.requestPasswordReset = 'idle';
    },
    [effects.resetPassword.pending]: (state) => {
      if (state.loading.resetPassword !== 'pending') {
        state.loading.resetPassword = 'pending';
      }
    },
    [effects.resetPassword.fulfilled]: (state, action) => {
      if (state.loading.resetPassword === 'pending') {
        state.loading.resetPassword = 'done';
      }
    },
    [effects.resetPassword.rejected]: (state) => {
      state.loading.resetPassword = 'idle';
    },
    [effects.handleActivityInquiry.pending]: (state) => {
      if (state.loading.handleActivityInquiry !== 'pending') {
        state.loading.handleActivityInquiry = 'pending';
      }
    },
    [effects.handleActivityInquiry.fulfilled]: (state, action) => {
      if (state.loading.handleActivityInquiry === 'pending') {
        state.loading.handleActivityInquiry = 'done';
      }
    },
    [effects.handleActivityInquiry.rejected]: (state) => {
      state.loading.handleActivityInquiry = 'idle';
    },
    [effects.handleAvailabilityInquiry.pending]: (state) => {
      if (state.loading.handleAvailabilityInquiry !== 'pending') {
        state.loading.handleAvailabilityInquiry = 'pending';
      }
    },
    [effects.handleAvailabilityInquiry.fulfilled]: (state, action) => {
      if (state.loading.handleAvailabilityInquiry === 'pending') {
        state.loading.handleAvailabilityInquiry = 'done';
      }
    },
    [effects.handleAvailabilityInquiry.rejected]: (state) => {
      state.loading.handleAvailabilityInquiry = 'idle';
    },
  },
});

export const { logout, onRedirect, setExistingAccountStatus } = actions;
export default reducer;
