import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import * as effects from './notificationsEffects';

const notificationsAdapter = createEntityAdapter();
const notificationsSelectors = notificationsAdapter.getSelectors();

const { actions, reducer } = createSlice({
  name: 'notifications',
  initialState: notificationsAdapter.getInitialState(),
  reducers: {
    showModal: (state, action) => {
      notificationsAdapter.addOne(state, {
        ...action.payload,
        type: 'modal',
        id: Math.round(new Date().getTime() / 1000),
      });
    },
    hideModal: (state, action) => {
      notificationsAdapter.removeOne(state, action.payload);
    },
  },
  extraReducers: {
    [effects.notify.pending]: (state, action) => {
      if (!notificationsSelectors.selectById(state, action.meta.requestId)) {
        notificationsAdapter.addOne(state, {
          ...action.meta.arg,
          id: action.meta.requestId,
        });
      }
    },
    [effects.notify.fulfilled]: (state, action) => {
      notificationsAdapter.removeOne(state, action.meta.requestId);
    },
  },
});

export const { showModal, hideModal } = actions;

export default reducer;
