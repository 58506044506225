import { createAsyncThunk } from '@reduxjs/toolkit';
import SearchAPI from '../../api/SearchAPI';
import PlacesAPI from '../../api/PlacesAPI';

export const fetchSearchSuggestions = createAsyncThunk(
  'search/fetchSuggestionsStatus',
  async (searchTerm) => {
    const { data } = await SearchAPI.fetchSuggestions({
      searchTerm,
      locationPredictionItemCount: 3,
      activityPredictionItemCount: 5,
      tourProviderPredictionItemCount: 3,
      activityTagPredictionItemCount: 2,
    });
    return data.value;
  },
);

export const fetchSearchPlaces = createAsyncThunk(
  'search/fetchPlacesStatus',
  async (searchTerm) => {
    const { data } = await PlacesAPI.placeSearch(searchTerm);
    // console.log('asdasd')
    // const photo = await PlacesAPI.requestPhoto(data.candidates[0].photos[0].photo_reference);
    // console.log(photo);
    return await Promise.all(data.candidates.map(async place => {
      console.log(place)
      const photo = await PlacesAPI.requestPhoto(data.candidates[0].photos[0].photo_reference)
      console.log(photo)
      return ({
        ...place, photoUrl: photo.data.url
      })
    }));
  },
);
